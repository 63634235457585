// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H2 from "../../../styleguide/components/Heading/H2.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as User from "../../../models/User.res.js";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Reservation from "../../../models/Reservation.res.js";
import * as SuccessImage from "./SuccessImage.res.js";
import * as ColocationProduct from "../../../models/ColocationProduct.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProductDetailsForSidebar from "../../product-detail/product-details-for-sidebar/ProductDetailsForSidebar.res.js";
import * as ProductReservationScss from "../ProductReservation.scss";

var css = ProductReservationScss;

function ProductReservationComplete$ProductReservationComplete(props) {
  var product = props.product;
  return JsxRuntime.jsxs(Container.make, {
              className: Cx.cx([
                    css.layout,
                    css.additionalPadding
                  ]),
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(SuccessImage.make, {}),
                        JsxRuntime.jsx(H2.make, {
                              className: css.formTitle,
                              children: "Here's What Happens Next:"
                            }),
                        JsxRuntime.jsxs("ul", {
                              children: [
                                JsxRuntime.jsx("li", {
                                      children: "You'll be contacted by a representative within 24-hours."
                                    }),
                                JsxRuntime.jsx("li", {
                                      children: "Thank you for using Datacenters.com!"
                                    })
                              ],
                              className: css.nextStepsList
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Button.make, {
                                    size: "LG",
                                    color: "Primary",
                                    expanded: true,
                                    onClick: (function (param) {
                                        Url.visit(product.url);
                                      }),
                                    children: "Okay"
                                  }),
                              className: css.buttonContainer
                            })
                      ],
                      className: css.formContainer
                    }),
                JsxRuntime.jsx(ProductDetailsForSidebar.make, {
                      product: product,
                      className: css.productDetailsComplete,
                      userLoginStatus: props.userLoginStatus,
                      setUserData: (function (prim) {
                          
                        }),
                      children: Caml_option.some(JsxRuntime.jsx("div", {
                                children: "Confirmation number: " + props.reservation.confirmationNumber,
                                className: css.confirmationNumberLabel
                              }))
                    })
              ]
            });
}

var ProductReservationComplete = {
  css: css,
  make: ProductReservationComplete$ProductReservationComplete
};

function ProductReservationComplete$default(props) {
  var props$1 = props.props;
  return JsxRuntime.jsx(ProductReservationComplete$ProductReservationComplete, {
              reservation: Reservation.fromJs(props$1.reservation),
              product: ColocationProduct.fromJs(props$1.product),
              userLoginStatus: User.LoginStatus.fromBool(props.context.isUserSignedIn)
            });
}

var $$default = ProductReservationComplete$default;

export {
  ProductReservationComplete ,
  $$default as default,
}
/* css Not a pure module */
